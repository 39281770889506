.loading-log-info {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 115%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.5);
    z-index: 9999;
  }

  .container-vods {
    display: flex;
    width: 100%;
    height: 95vh;
    background-color: #4a4747
  }
  
  .iframe-vod {
    padding: 2px;
    border: 2px solid #555;
    width: 90%;
    height: 100%;
    background-color:  #4a4747;
  }

  .right-section-vods {
    width: 10%;
    min-width: 330px;
    display: inline-block;
    flex-direction: column;
    height: 100%;
    padding: 2px;
    border: 2px solid #555;
    overflow-y: auto;
    overflow-x: hidden;
    background-color: rgba(211, 211, 211, 0.248);
  }

  .right-section-dropdowns-container {
    border: 1px #4527A0;
    background-color: rgba(115, 112, 112, 0.414)
  }

  .dropdown-vods {
    width: 100%;
  }

  .vods-dropdown.dropup{
    color: #007bff;
   }

   .channel-dropdown {
    border-radius: 1px;
  }

  .dropdown-menu.show{
    background-color:#303030;
    border: 1px solid #555;
  }

  .dropdown-toggle-with-image{
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: 40px;
  }

  .dropdown-toggle-with-image img {
    height: 20px;
    max-height: 20px;
    width: auto;
    margin-right: 8px; /* optional, adds some spacing between the image and text */
  }
  .channel-dropdown .dropdown-toggle {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .refreshBtn{
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    color: whitesmoke;
    background-color: rgb(20 20 20 / 66%);
    border: 1px solid #555;
    border-bottom: 2px solid #555;
  }
  
  .refreshLoadingSpinner{
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 32px;
    max-height: 32px;
  }

  .refreshBtn:hover{
    cursor: pointer;
    border: 2px solid #555;
    background-color: rgba(255, 255, 255, 0.5);



  }
  .refreshBtnIcon {
    z-index: 99;
    ;
  }

  .refreshBtnIcon:hover {
    scale: 1.05;
    color: black;
  }

 