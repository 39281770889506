.encounter-container {
  width: 100px;
  height: 95%;
  min-width: 300px;
  display: flex;
  overflow-x: none;
  /* import '../styles/PullList' */
}

.encounter-container:hover {
  border: 2px solid white;
  z-index: 999;
}

.encounter-container {
  width: 100%;
  height: 100%;
  border: 1px solid #303030;
  display: grid;
  grid-template-columns: 20% 80%;
  cursor: pointer;
  min-height: 50px;
  max-height: 50px;
  margin-bottom: 1px;
}

.encounter-container-left {
  width: 100%;
  height: 100%;
  border: 1px solid #303030;
  display: grid;
  grid-template-rows: 50% 50%;
  justify-content: center;
  align-items: center;
  background-color: rgb(13, 13, 13, 0.76);
}

.encounter-container-left-kill {
  color: #80e666;
  font-weight: bold;
}

.encounter-container-left-checkmark {
  width: 70%;
  height: 70%;
  border-radius: 50%;
  background-color: #80e666;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  align-self: center;
  justify-self: center;
  margin-top: -15%;
}

.encounter-container-left-wipe-percentage {
  align-self: center;
  justify-self: center;
}

.encounter-container-left-wipe {
  color: #b6b6b6;
  align-self: center;
  justify-self: center;
  white-space: nowrap;
  font-size: small;


}



.pull-percentage {
  height: 100%;
  width: 80%;
  min-height: 3px;
  margin-top: -2px;
  margin-bottom: 3px;
  margin-left: 4px;
  border: 1px goldenrod;
  background-color: #8b8b8b;
  align-self: center;
  justify-self: left;
}

.pull-percentage-bar {
  z-index: 11;
  height: 100%;
}

.encounter-container-right {
  width: 100%;
  min-width: 215px;
  background-color: rgb(20 20 20 / 66%);
  border: 1px solid #303030;
  display: grid;
  flex-direction: column;
  align-items: center;
  grid-template-rows: 95% 5%;

}

.encounter-container-right-text-container {
  display: grid;
  grid-template-columns: 40% 40% 20%;
  margin-bottom: -10px
}

.encounter-container-right-text-pull-time {
  align-self: left;
  justify-self: left;
  white-space: nowrap;
  margin-left: 4px;
}

.encounter-container-right-text-encounter-time {
  white-space: nowrap;
  align-self: right;
  justify-self: right;
  color: #b6b6b6;
  margin-right: -4px;
}

.encounter-container-right-text-encounter-link {
  justify-self: right;
  align-self: top;
  z-index: 2;
  max-width: 20px;
  max-height: 20px;
  scale: 0.3;
  margin-right: 10px;
  align-items: right;
  justify-content: right;
  margin-top: 5px;
}

.encounter-container-right-text-encounter-link:hover {
  scale: 0.35;
}

.triangle {
  display: inline-block;
  margin-left: .255em;
  vertical-align: .255em;
  content: "";
  border-top: .3em solid;
  border-right: .3em solid transparent;
  border-bottom: 0;
  border-left: .3em solid transparent;
  color: lightgreen;
}

.encounter-container-encounter-name{
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  height: 100%;
  min-height: 100%;
  margin-bottom: -0.5px;
  margin-top: -1px;
  background-color: rgba(20, 20, 20, 0.767);
}
.encounter-container-encounter-name:hover{
  cursor: pointer;
  background-color: rgba(20, 20, 20, 0.5);
}

.pull-list{
  margin-top: 1px;
}