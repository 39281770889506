.frontpage{
    width: 100%;
    height: 95vh;
    background-color: #a5a5a5;
    overflow-y: hidden;
    justify-content: center;
    display: flex;
}

.frontpage-background{
    margin-top: 10px;
    width: 60%;
    height: 70%;
    justify-content: center;
}

.frontpage-logo {
    width: 5000px;
    height: 5000px;
    display: flex;
    background-color: pink;
    margin-bottom: 20px;
}

.frontpage-logo-img{
    max-width: 100%;
    max-height: 100%;
    scale: 0.9;
    align-self: center;
}

.frontpage-disclaimer-text {
    color: rgb(124, 124, 124);
    position: absolute;
    bottom: 0;
    left: 0;
    margin-left: 0%;
    font-size: small;
}

.frontpage-messages {
    margin: 0 auto;
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    display: grid;
    min-width: 1250px;
    max-width: 1250px;
    grid-template-columns: 50% 50%;
    background-color: #a5a5a5;
    justify-self: center;
    align-items: center;
  }

.frontpage-row-container-right{
    display: grid;
    grid-template-rows: 40% 60%;
    align-self: center;
    justify-self: center;
}
.frontpage-row-container-left{
    display: grid;
    grid-template-rows: 40% 60%;
    justify-content: center;
    align-items: center;
    
}
.frontpage-text-container{
    min-height: 100%;
    margin-left: 5px;
    margin-right: 5px;
}

.frontpage-text-area{
    font-weight: bold;
    border: 1px solid #303030;
    text-align: left;
    padding: 10px;
    color: lightgray;
    background-color: #313030;
    margin-bottom: 0;
}

.frontpage-gif{
}

.youtube-text-highlight{
  color: red;
}

.warcraftlogs-text-highlight{
    color: grey;
}

.epic-text-highlight{
    color: #a335ee;
}

.rare-text-highlight{
    color: #0070ff;
}
.legendary-text-highlight{
color: #e268a8
}

.frontpage-text-header{
    font-weight: bold;
    margin-top: 3%;
}

.twitch-text-highlight{
    color: #6441a5;
}

.frontpage-buttons{
    margin-top: -2%;
    display: grid;
    grid-template-columns: 30% 30% 40%;
    justify-content: center;
    align-items: center;
}

.frontpage-buttons {
    margin-top: -4%;
    display: grid;
    grid-template-columns: 30% 30% 40%;
    max-width: 99%;
}

.frontpage-link-button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    min-height: 52px;
    border-radius: 5px;
    color: lightgray;
    cursor: pointer;
    border: 1px solid #303030;
    margin-left: 2%;
    background-color: #313030;
}
.frontpage-link-button:hover{
    border: 1px solid white;
}

.discord .icon {
    width: 30px;
    height: 30px;
    background-image: url('../discord-icon.png');
    background-size: contain;
    background-repeat: no-repeat;
    margin-right: 5px;
}

.patreon .icon {
    width: 30px;
    height: 30px;
    background-image: url('../patreon-icon.png');
    background-size: contain;
    background-repeat: no-repeat;
    margin-right: 5px;
}

.frontpage-login-button.warcraftlogs-authed{
    background-color: rgb(38, 209, 38);
    color: #303030;
}
.frontpage-login-button.warcraftlogs-authed:hover{
    border: 1px solid white;
}
.warcraftlogs-authed .icon{
    width: 30px;
    height: 30px;
    background-image: url('../warcraftlogs-image.png');
    background-size: contain;
    background-repeat: no-repeat;
    margin-right: 5px;
}

.frontpage-login-button.warcraftlogs-no-auth{
    background-color: red;
    color: #303030;
}
.frontpage-login-button.warcraftlogs-no-auth:hover{
    border: 1px solid #303030;
    cursor: default;
}
.warcraftlogs-no-auth .icon{
    width: 30px;
    height: 30px;
    background-image: url('../warcraftlogs-image.png');
    background-size: contain;
    background-repeat: no-repeat;
    margin-right: 5px;
}

.text {
    font-size: 16px;
    font-weight: bold;
}

.frontpage-login-button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-radius: 5px;
    min-height: 52px;
    color: lightgray;
    cursor: pointer;
    border: 1px solid #303030;
    margin-left: 2%;
    background-color: #313030;
    font-weight: bold;
}

.frontpage-login-button:hover{
    border: 1px solid white;
}


.frontpage-blurred{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    float: bottom;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #3f3e3e7b;
    z-index: 5;
  }
  
  .login-messages {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #a5a5a5;
    padding: 20px;
    border: 2px solid #303030;
  }
  
  .login-messages form {
    width: 250px;
  }

  .login-cross-icon{
    width: 15x;
    height: 15px;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    
  }
  .login-cross-icon:hover{
    cursor: pointer;
    scale: 1.1;
  }

