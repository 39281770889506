.timeline-settings-dialog {
    position: absolute;
    bottom: 100%;
    right: 0;
    z-index: 1;
    background-color: #303030;
    border: 2px solid #555;
    color: white;
    margin-bottom: 10px;
    display: grid;
    max-width: 450px;
    min-height: 400px;
    max-height: 700px;
    grid-template-rows: 12% 8% 25% 40% 1fr;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  }
  
  .timeline-settings-dialog-tabs {
    display: grid;
    grid-template-columns: 50% 50%;
    text-align: center;
  
    margin-bottom: 0px;
  }
  
  .timeline-settings-dialog-tabs-tab {
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #555;
    height: 100%;
  }
  
  .timeline-settings-dialog-tabs-tab-main {
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #555;
    border-bottom: 1px solid lightgreen;
    height: 100%;
  }
  
  .timeline-settings-dialog-tabs-tab:hover {
    cursor: pointer;
    background-color: #8f8f8f;
  }
  
  .timeline-settings-dialog-tabs-tab-main:hover {
    cursor: pointer;
    background-color: #8f8f8f;
  }
  
  .timeline-settings-dialog:hover {
    cursor: default;
  }
  
  .timeline-settings-dialog-header {
    border: 1px solid #555;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    padding-top: 5px;
    justify-content: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .timeline-settings-dialog-information {
    border: 1px solid #555;
    height: 100%;
    overflow-wrap: normal;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10%;
    padding-top: 0px;
    padding-bottom: 0px;
  }
  
  .timeline-settings-dialog-offset-container {
    display: grid;
    grid-template-columns: 60% 40%;
    max-width: 100%;
    align-items: center;
  }
  
  .timeline-settings-dialog-offset-title {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0px;
    margin-left: 0px;
  }
  
  .timeline-settings-dialog-offset-title {
    cursor: default;
  }
  
  .timeline-settings-dialog-offset-input {
    border-radius: 5px;
    background-color: lightgray;
    align-items: center;
    text-align: center;
    margin-right: 10%;
    margin-left: -10%;
  }
  
  .timeline-settings-dialog-button-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .timeline-settings-dialog-button-confirm {
    width: 30%;
    background-color: lightgreen;
    border-radius: 5px;
    font-weight: bold;
  }
  
  .timeline-settings-dialog-button-confirm:hover {
    border: 1px solid white;
    background-color: rgb(193, 244, 193);
  }
  
  .timeline-settings-dialog-offset-input.input-highlight {
    background-color: rgb(193, 244, 193);
  }
  
  .timeline-settings-dialog-events-container {
    display: grid;
    grid-template-columns: 50% 50%;
    justify-content: center;
  }
  
  .timeline-settings-dialog-events-container:hover {
    cursor: default;
  }
  
  .timeline-settings-dialog-event-header {
    border-bottom: 1px solid #555;
    border-top: 0px;
    height: 100%;
    display: flex;
    margin-bottom: 5px;
    max-height: 45px;
    justify-content: center;
    /* Updated */
    align-items: center;
  }
  
  .timeline-settings-dialog-events-container-container {
    display: inline;
    max-height: 400px;
    overflow-y: auto;
    width: 100%;
    border: 1px solid #555;
  }
  
  .timeline-settings-dialog-event-container {
    display: grid;
    grid-template-columns: 25% 1fr 25%;
  }
  
  .timeline-settings-dialog-event-checkbox {
    background-color: lightgray;
    height: 70%;
    width: 60%;
    color: lightgray;
    appearance: none;
    border-radius: 5px;
    border: 1px solid #555;
    display: flex;
    justify-content: center;
    /* Updated */
    align-items: center;
    /* Added */
  }
  
  .timeline-settings-dialog-event-checkbox {
    cursor: pointer;
  }
  
  .timeline-settings-dialog-event-checkbox:checked {
    background-color: blue;
    color: white;
  }
  
  .timeline-settings-dialog-event {
    display: flex;
    justify-content: center;
    /* Updated */
    align-items: center;
    /* Added */
    height: 100%;
  }
  
  .timeline-settings-dialog-event-adjust-input {
    border-radius: 5px;
    height: 70%;
    width: 30%;
    max-height: 45px;
    background-color: lightgray;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .timeline-settings-dialog-event-adjust-input-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .timeline-settings-dialog-event-event-input-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
  }

  .dropdown-bossevent-icon{
    scale: 70%;
    justify-self: center;
    align-self: center;
    word-wrap: break-word;
    font-size: small;
  }
  
  .dropdown-bossevent-icon-text{
    word-wrap: break-word;
    text-size-adjust: 90%;
    font-size: 14px;
  }
  .dropdown-bossevent-icon-death{
    object-fit: contain;
    max-width: 45px;
    max-height: 45px;
    justify-content: center;
    display: flex;
    justify-self: center;
    align-self: center;
  }
  
  .dropdown-bossevent-item.dropdown-item{
    border: 1px solid #555;
    background-color: #303030;
    color: lightgray;
    border-top: 0px;
  }
  .dropdown-bossevent-item.dropdown-item:hover{
    border: 1px solid #555;
    background-color: #7d7d7d;
  }
  
  .dropdown-item{
    border: 1px solid #555;
    background-color: #303030;
    color: lightgray;
    border-top: 0px;
  }
  .dropdown-item:hover{
    border: 1px solid #555;
    background-color: #7d7d7d;
  }
  
  .dropdown-bossevent-menu.dropdown-menu{
    background-color: #303030;
    border: 0px;
    max-height: 450px;
    max-width: 100%;
    overflow-y: auto;
    border: 1px solid #555;
  
  }
  
  .dropdown-bossevent-button.dropdown-toggle.btn.btn-success{
    background-color: #303030;
    height: 100%;
  
  }