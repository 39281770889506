.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.bosslist-container {
  min-height: 150px;

}

.body{
  color: #373a47;
}

.openDialog {
  align-items: center;
  left: 50%;
  color: pink;
  font-size: large;
}

.TopGrid {
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  grid-auto-flow: column;
  border: pink;
  border-radius: 1px;
  min-height: 100%;

}

.navbar-brand {
  margin-left: 1%;
}

.navbar.navbar-expand {
  height: 50px;
}

/* .TopGrid > div:first-of-type{
  margin-top: 0%;
  padding-top: 25%;
  width: 5px;
} */

/* Create two equal columns that floats next to each other */
.column {
  float: left;
  width: auto;
  padding: 10px;
}

/* Clear floats after the columns */
.row:after {
  content: "Test";
  display: table;
  clear: both;
}

.grid-container {

  display: grid;
  grid-template-columns: repeat(10, 1fr);
  background-color: grey;

}

.grid-container>div {
  background-color: rgba(255, 255, 255, 0.8);
  text-align: center;
  font-size: 22px;
}

.grid-container-top {
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  background-color: grey;

}

.grid-container-top>div {
  background-color: rgba(255, 255, 255, 0.8);
  text-align: center;
  font-size: 15px;
  font-weight: bold;
  cursor: default;

}

/* .grid-container-top > div:first-child{
  background-color: rgba(255, 255, 255, 0.8);
  text-align: center;
  font-size: 15px;
  font-weight: bold;
} */
.menuIcon {
  width: 25px;
  height: 1px;
  background-color: black;
  margin: 6px 0;
}

.dropDown {
  align-items: center;
  padding-top: 20%;
  padding-left: 5%;
  padding-right: 0%;
  width: 25px;
}

.cell {
  background-color: whitesmoke;
  border-color: rgb(157, 89, 115);
  border-width: 0.5px;
  cursor: pointer;

}

.cellExportBtn {
  background-color: whitesmoke;
  border-color: rgb(157, 89, 115);
  border-width: 0.5px;
  max-height: 40px;
  cursor: pointer;

}

.cellName {
  background-color: whitesmoke;
  border-color: black;
  filter: brightness(2.5);
  cursor: pointer;

}

.cellNameDeclined {
  background-color: rgba(245, 245, 245, 0.384);
  border-color: black;
  opacity: 10%;
  cursor: pointer;


}

.cellCount {
  background-color: whitesmoke;
  color: transparent;
}

.red {
  color: red
}

.green {
  color: lightgreen
}

ul {
  list-style-type: none;
  padding: 0;
  padding-top: 0;
  border: 1px solid #ddd;
  background-color: whitesmoke;
  margin-top: 0;
}

.navBar {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: rgb(157, 89, 115);
  font-weight: bold;
  border: 5px;
  box-shadow: #007bff;
  box-shadow: inset;
}

li a:hover {
  background-color: #216805;
  cursor: pointer;
}

li {
  float: left;
}

li a {
  display: block;
  color: white;
  text-align: center;
  padding: 16px;
  text-decoration: none;
}

p {
  text-align: center;
  font-weight: bold;

}

body.dragging .p>div {
  cursor: grabbing;
}

.benchOnBoss {
  text-align: center;
}

/* loader  */

.emptyCell {
  height: 10px;
  background-color: darkgray;
  color: darkgray;
}

.centerDiv {
  padding-left: 50%;
  padding-top: 20%;
  padding-right: 50%;
  padding-bottom: 50%;
}

/* Sidebar */

#page-wrap {
  text-align: center;
  overflow: auto;
}

.inputGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.discordInput {
  width: 70px;
  border-radius: 0%;
  border: 0px;
  background-color: lightblue;
  font-weight: bold;
}

.sheetInput {
  width: 70px;
  border-radius: 0%;
  border: 0px;
  background-color: burlywood;
  font-weight: bold;
}

.saveDiscordNames {
  width: 74px;
  color: black;
  background-color: green;
  border: 3px;
}



#dropdown-basic {
  background-color: rgb(20 20 20 / 66%);
  border: 1px solid #555;
  font-weight: bold;
  margin-bottom: 1px;
}

#dropdown-basic:hover {
  background-color: rgba(193, 187, 187, 0.66)
}

label {
  font-size: 15px;
  padding: 5px;
  float: left;
}

.bm-item {
  display: inline-block;
  text-decoration: none;
  margin-bottom: 15px;
  color: #d1d1d1;
  transition: color 0.2s;
}

.bm-item:hover {
  color: white;
}

.bm-burger-button {
  top: 20px;
  position: relative;
  float: left;
  width: 36px;
  height: 30px;
}

.bm-burger-bars {
  background: #373a47;
}

.bm-cross-button {
  height: 24px;
  width: 24px;
}

.bm-cross {
  background: #bdc3c7;
}

.bm-menu {
  background: #373a47;
  padding: 2.5em 1.5em 0;
}

.bm-morph-shape {
  fill: #373a47;
}

.bm-item-list {
  color: #b8b7ad;
}

.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

.wip-pic {
  height: 250;
  width: 250;
  top: 60%;
  left: 50%;
  position: fixed;
  transform: translate(-50%, -50%);

}

.picture-container {
  position: relative;
  text-align: center;
  color: white;
}

.picture-header {
  font-size: x-large;
  color: #282c34
}

/* Video selection list */

.vod-page-layout {
  display: flex;
}

.vod-side-panel {
  display: inline-block;
  position: relative;
  float: right;
  width: fit-content;
}

.vod-video {
  display: inline-block;
  width: 100%;
}

body {
  background: #4527A0;
}

.list-group {
  width: 100% !important;

}

.list-group-item {
  margin-top: 10px;
  border-radius: none;
  background: #5E35B1;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}


.list-group-item:hover {
  transform: scaleX(1.1);
}

.loggedInStatusContainer {
  display: flex;
  justify-content: right;
  text-align: center;
}

.loggedInStatus {
  display: inline-block;
  color: lightgray;
  padding-right: 10px;
  height: 10px;
  padding-top: 4px;
  font-size: large;

}

.logoutBtn {
  width: 10%;
  height: 10%;
}

.logoutBtn:hover {
  cursor: pointer;
}



.wclLogo {
  width: 35;
  height: 20;
}


.form-label{
  font-weight: bold;
}
#formBasicPassword{
  background-color: #cfcfcf;
  border-radius: 1px;
  border: 1px solid #303030;
}

#formBasicUsername{
  background-color: #cfcfcf;
  border-radius: 1px;
  border: 1px solid #303030;
  
}

.frontPageImages {
  display: inline-block;
}

.frontPageWCL {
  align-items: center;
}

.frontPageYT {
  height: 70px;
}

.loggedInFrontPageMessage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.loggedInFrontPageMessage h3 {
  font-size: 24px;
  font-weight: bold;
  margin-top: 20px;
}

.btn.btn-primary.btn-lg {
  display: "flex";
  flex-direction: "column";
  align-items: "center";
  border-radius: 1px;
  width: 100%;

}

.btn.btn-secondary.btn-lg {
  display: "flex";
  flex-direction: "column";
  align-items: "center";
  border-radius: 1px;
}

.btn.btn-danger.btn-lg {
  display: "flex";
  flex-direction: "column";
  align-items: "center";
  justify-content: center;
  border-radius: 1px;
  width: 100%;
}

.btn.btn-secondary {
  border-radius: 1px;
}

.btn.btn-primary {
  border-radius: 1px;
}

.loginButtons {
  display: flex;
  justify-content: center;
}



iframe {
  height: 100%;
  width: 100%;
}

.vod-timeline-container:hover {
  cursor: pointer;
}

#dropdown-basic {
  border-radius: 0px;
  width: 100%;
}

.dropdown-menu.show {
  border-radius: 0px;
  width: 100%;
}

.list-group-item {
  padding: 0;
  border-radius: 0px;
  margin-top: 0%;
  border: none;
}






::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #303030;
}

::-webkit-scrollbar-thumb {
  background-color: #888;
}

/* Firefox */
::-moz-scrollbar {
  width: 10px;
}

::-moz-scrollbar-track {
  background: #303030;
}

::-moz-scrollbar-thumb {
  background-color: #888;
}