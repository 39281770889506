.iframe-vod {
  padding: 2px;
  border: 2px solid #555;
  width: 85%;
  height: 100%;
  background-color: lightgray;
}

.video-player-container {
  height: 100%;
}

.vod-timeline-container-all {
  height: 5%;
  border: 2px solid #555;
  background-color: #303030;
  display: grid;
  grid-template-columns: 5% 88% 1fr 1fr 1fr;
  position: relative;
}

.vod-timeline-container {
  height: 100%;
  max-height: 100%;
  width: 100%;
  background-color: #303030;
  position: relative;
}

.currentTime {
  color: white;
  font-size: larger;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #555;
  border-top: 0px;
}

.vod-timeline-tooltip {
  position: absolute;
  bottom: 100%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0);
  color: white;
  font-size: 1.9rem;
  padding: 0.2rem;
  border-radius: 0.2rem;
  white-space: nowrap;
  z-index: 9999;
  min-height: 50px;
  border: 1px solid #555;
  margin-bottom: 3px;
  background-color: #303030;

}

.vod-timeline-container:hover::before,
.vod-timeline-container[data-is-hovering="true"]::before {
  content: "";
  position: absolute;
  width: 2px;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.519);
  top: 0;
  left: var(--tooltip-position);
}

.vod-timeline {
  background-color: rgba(255, 0, 0, 0.745);
  border: 1px solid #555;
  border-right: 0px;
  height: 100%;
  max-height: 100%;
  max-width: 100%;
  width: 0%;
  /* set initial width to 0 */
}

.vod-timeline-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.vod-timeline-highlight {
  position: absolute;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.vod-timeline-highlight-picture {
  width: auto;
  height: 100%;
  object-fit: contain;
  transform: translate(-50%, -50%) scale(0.5);
  position: absolute;
  top: 50%;
  left: 50%;
}

.vod-option {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #555;
  border-top: 0px;
  object-fit: contain;
  max-height: 100%;
  max-width: 100%;
}


.vod-option-icon {
  scale: 70%;
  height: 80%;
  width: 80%;
  max-height: 80%;
  max-width: 80%;
  object-fit: contain;
}

.vod-option:hover {
  cursor: pointer;
  border: 1px solid white;
}